import React from 'react'
import { silentAuth } from './auth'

interface ISessionState {
    loading: boolean
}

export class SessionCheck extends React.Component<{}, ISessionState> {
    constructor(props = {}) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    public handleCheckSession = () => {
        this.setState({ loading: false })
    }

    public componentDidMount() {
        // this.setState({ loading: false })
        silentAuth(this.handleCheckSession)
    }

    public render() {
        return (
            this.state.loading === false && (
                <React.Fragment>{this.props.children}</React.Fragment>
            )
        )
    }
}
