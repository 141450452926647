import auth0 from 'auth0-js'
import { navigate } from 'gatsby'

interface IToken {
  accessToken: string | boolean;
  idToken: string | boolean;
  expiresAt: number | boolean;
}

type ICallback = (callback?: any) => void | boolean

const isBrowser = typeof window !== 'undefined'
const auth: auth0.WebAuth | undefined = isBrowser
  ? new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN || '',
    clientID: process.env.AUTH0_CLIENTID || '',
    responseType: 'token id_token',
    redirectUri: process.env.AUTH0_CALLBACK,
    scope: 'openid profile email',
  })
  : undefined
const tokens: IToken = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}
let user = {}

export function isAuthenticated(): any {
  if (!isBrowser) {
    return
  }
  if (localStorage.getItem('isLoggedIn') !== 'true') {
    return false
  }

  const expiresAt = parseInt(localStorage.getItem('expiresAt') || '0', 10)
  const currTime = new Date().getTime()
  if (currTime >= expiresAt) {
    localStorage.setItem('isLoggedIn', 'false')
    localStorage.setItem('idToken', 'false')
    localStorage.setItem('expiresAt', 'false')
    localStorage.removeItem('redirectUri')
    return false
  }

  return true
}

export function login(): void {
  if (!isBrowser) {
    return
  }

  if (auth) {
    auth.authorize()
  }
}

function setSession(cb: any = () => void 0): auth0.Auth0Callback<any> {

  return (err: any, authResult: any) => {
    if (err) {
      cb()
      return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
      tokens.accessToken = authResult.accessToken
      tokens.idToken = authResult.idToken
      tokens.expiresAt = expiresAt
      user = authResult.idTokenPayload

      localStorage.setItem('isLoggedIn', 'true')
      localStorage.setItem('idToken', tokens.idToken as string)
      localStorage.setItem('expiresAt', tokens.expiresAt.toString())

      const redirectUri = localStorage.getItem('redirectUri')
      localStorage.removeItem('redirectUri')

      if (redirectUri) {
        navigate(redirectUri, { replace: true })
        // Return to the original page
        //  0: https://gigony.com/callback/#access_token..  -1: Sign In with Auth0  -2: Login - Google Account  -3: Original URL
        // window.history.go(-3)
        // Remove forward history which was used for login (below code doesn't work after history.go(-3)
        // history.pushState(null,  document.title, location.href);
      }
      cb()
    }
  }
}

export function silentAuth(callback: ICallback): void {
  if (!isAuthenticated()) {
    callback()
  } else {
    if (auth) {
      auth.checkSession({}, setSession(callback))
    }
  }
}

export function handleAuthentication(): void {
  if (!isBrowser) {
    return
  }

  if (auth) {
    auth.parseHash(setSession())
  }
}

export function getProfile(): any {
  return user
}

export function getIdToken(): string {
  return localStorage.getItem('idToken') || ''
}

export function logout(returnTo: string): void {
  localStorage.setItem('isLoggedIn', 'false')
  localStorage.setItem('idToken', 'false')
  localStorage.setItem('expiresAt', 'false')
  localStorage.removeItem('redirectUri')
  if (returnTo) {
    if (auth) {
      auth.logout({
        clientID: process.env.AUTH0_CLIENTID,
        returnTo: window.location.origin,
      })
    }
  } else {
    if (auth) {
      auth.logout({})
    }
  }
}
